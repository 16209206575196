import React from "react"
import Layout from "../components/layout/layout"
import Hero from "../components/hero"
import BookingFeatures from "../components/BookingFeatures"
import OnePager from "../components/feature6"
import BookingContact from "../components/BookingContact"
import VideoFeature from "../components/VideoFeature"
import BookingClients from "../components/booking_clients"
import { graphql } from "gatsby"

export default function BookingType({ data, pageContext, location }) {
  const booking_type = data.sanityBookingTypes
  const page = data.sanityPages
  const feature = {
    headline: "Bio Feature Headline",
    text: booking_type._rawOnePager.text,
    image: booking_type.one_pager.icon.asset.fluid.src,
    pdf: booking_type.one_pager.pdf.asset.url,
  }
  const video_highlight = {
    text: booking_type._rawVideoHighlight.text,
    image: booking_type.video_highlight.video.cover_image.asset.fluid,
    video: booking_type.video_highlight.video.url,
  }
  let hero_data = {
    ...booking_type,
  }
  if (!hero_data.hero_bg) hero_data.hero_bg = page.hero_bg

  const clients = {
    title: "You're in Good Company",
    clients: booking_type.clients,
  }
  return (
    <Layout>
      <Hero data={hero_data} />
      <VideoFeature data={video_highlight} />
      <BookingFeatures features={booking_type.features} />
      <OnePager feature={feature} />
      {booking_type.clients.length > 0 && <BookingClients data={clients} />}
      <BookingContact />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BookingTypeBySlug($slug: String!) {
    sanityPages(slug: { current: { eq: "hire" } }) {
      hero_bg {
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
            src
          }
        }
      }
      hero_subtitle
      hero_title
    }
    sanityBookingTypes(slug: { current: { eq: $slug } }) {
      _rawOnePager(resolveReferences: { maxDepth: 10 })
      video_highlight {
        video {
          url
          cover_image {
            asset {
              fluid(maxWidth: 350) {
                ...GatsbySanityImageFluid
                src
              }
            }
          }
        }
      }
      _rawVideoHighlight(resolveReferences: { maxDepth: 10 })
      one_pager {
        blurb
        icon {
          asset {
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
              src
            }
          }
        }
        pdf {
          asset {
            url
          }
        }
      }
      hero_subtitle
      hero_title
      hero_bg {
        hotspot {
          x
          y
        }

        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
            src
          }
        }
      }
      id
      slug {
        current
      }
      title
      short_description
      clients {
        name
        picture {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
              src
            }
          }
        }
      }
      icon {
        asset {
          fluid(maxWidth: 150) {
            ...GatsbySanityImageFluid
            src
          }
        }
      }
      features {
        description
        title
        picture {
          asset {
            fluid(maxWidth: 150) {
              ...GatsbySanityImageFluid
              src
            }
          }
        }
      }
    }
  }
`
