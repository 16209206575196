import React from "react"

export default props => {
  return (
    <div
      className="bg-light position-relative mt-10"
      style={{ marginBottom: "-25px" }}
    >
      <div className="container space-bottom-1 space-bottom-md-2">
        <div className="row justify-content-center mb-5">
          <div className="col-md-10 col-lg-9 mt-n11">
            <form
              className="js-validate card p-4 p-md-5"
              method="POST"
              name="Booking"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="Booking" />
              <div className="text-center mb-4">
                <h2>Hey, Rajiv, I'd like to...</h2>
                <p>
                  Hire you, interview you, seek your comedic advice, or give you
                  a piece of my mind.
                </p>
              </div>

              <div className="row mx-n2">
                <div className="col-md-6 px-2 mb-4">
                  <label className="input-label" htmlFor="first_name">
                    First name
                  </label>
                  <div className="js-form-message">
                    <div className="input-group">
                      <input
                        id="first_name"
                        type="text"
                        className="form-control"
                        name="firstName"
                        placeholder="First name"
                        aria-label="First name"
                        required
                        data-msg="Please enter your first name."
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 px-2 mb-4">
                  <label className="input-label" htmlFor="last_name">
                    Last name
                  </label>
                  <div className="js-form-message">
                    <div className="input-group">
                      <input
                        id="last_name"
                        type="text"
                        className="form-control"
                        name="lasstName"
                        placeholder="Last name"
                        aria-label="Last name"
                        required
                        data-msg="Please enter your last name."
                      />
                    </div>
                  </div>
                </div>

                <div className="w-100"></div>

                <div className="col-md-6 px-2 mb-4">
                  <label className="input-label" htmlFor="email">
                    Email address
                  </label>
                  <div className="js-form-message">
                    <div className="input-group">
                      <input
                        id="email"
                        type="text"
                        className="form-control"
                        name="email"
                        placeholder="Email address"
                        aria-label="Email address"
                        required
                        data-msg="Please enter a valid email address."
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 px-2 mb-4">
                  <label className="input-label" htmlFor="company">
                    Company{" "}
                    <span className="text-muted font-weight-normal ml-1">
                      (optional)
                    </span>
                  </label>
                  <div className="js-form-message">
                    <div className="input-group">
                      <input
                        id="company"
                        type="text"
                        className="form-control"
                        name="company"
                        placeholder="Company"
                        aria-label="Company"
                        data-msg="Please enter company name."
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-5">
                <label className="input-label" htmlFor="budget">
                  What's your budget?
                  <span className="text-muted font-weight-normal ml-1">
                    (optional)
                  </span>
                </label>
                <div className="js-form-message input-group">
                  <textarea
                    id="budget"
                    type="text"
                    className="form-control"
                    name="budget"
                    placeholder="My budget is ..."
                    aria-label="My budget is ..."
                    data-msg="Please enter your budget."
                  ></textarea>
                </div>
              </div>

              <div className="mb-5">
                <label className="input-label" htmlFor="comments">
                  How can Rajiv help?
                </label>
                <div className="js-form-message input-group">
                  <textarea
                    id="comments"
                    className="form-control"
                    rows="4"
                    name="description"
                    placeholder="Hi there, I would like to ..."
                    aria-label="Hi there, I would like to ..."
                    required
                    data-msg="Please enter a reason."
                  ></textarea>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary transition-3d-hover"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
