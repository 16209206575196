import React from "react"
import Img from "gatsby-image"

export default props => {
  return (
    <div className="container space-2">
      <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
        <h2>{props.data.title}</h2>
      </div>
      <div className="row justify-content-between text-center">
        {props.data.clients.map((item, key) => (
          <div className="col-4 col-lg-2 mb-5 mb-lg-0" key={key}>
            <div className="mx-3">
              <Img
                className="max-w-11rem max-w-md-13rem mx-auto"
                fluid={item.picture.asset.fluid}
                alt={item.name}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
