import React from "react"
import BlockContent from "@sanity/block-content-to-react"

export default props => {
  return (
    <div className="container space-2">
      <div className="row justify-content-lg-between">
        <div className="col-lg-6 mb-5 mb-md-0">
          <div className="position-relative max-w-50rem mx-auto">
            <img
              className="img-fluid"
              src={props.feature.image}
              alt="Instagram Post"
            />
          </div>
        </div>
        <div className="col-lg-5 mb-0">
          <div className="mb-0">
            {/* <h2>{props.feature.headline}</h2> */}
            <BlockContent blocks={props.feature.text} />
            {props.feature.pdf && (
              <div className="text-left">
                <a
                  className="btn btn-primary transition-3d-hover text-left mt-4 mb-1 mb-sm-0 mx-1"
                  rel="noopener noreferrer"
                  href={props.feature.pdf}
                  target="_blank"
                >
                  Download PDF
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
