import React from "react"

export default props => {
  return (
    <div className="container space-2">
      <div className="row justify-content-lg-center">
        {props.features.map((item, key) => (
          <div className="col-md-6 col-lg-5" key={key}>
            <div className="media pl-lg-5">
              {item.picture && (
                <figure className="w-100 max-w-8rem mr-4">
                  <img
                    className="img-fluid"
                    src={item.picture.asset.fluid.src}
                    alt="SVG"
                  />
                </figure>
              )}
              <div className="media-body">
                <h4>{item.title}</h4>
                {item.description && <p>{item.description}</p>}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
